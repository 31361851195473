import React from 'react';
import classnames from 'classnames';

import style from './push-button.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  href: string;
  onClick?: () => void;
};

const noop = () => {};

export function PushButton(props: Props) {
  const { children, className, href, onClick = noop } = props;

  return (
    <div className={classnames(style.root, className)}>
      <a href={href} className={style.button} onClick={onClick}>
        {children}
      </a>
      <div className={style.background} />
    </div>
  );
}
