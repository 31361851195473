import classNames from 'classnames';
import React, { ReactNode } from 'react';

import style from './text.module.scss';

type TextProps = {
  children: ReactNode;
  className?: string;
  isCenter?: boolean;
};

type TextWithFuriganaProps = {
  children: string;
  className?: string;
};

export const regex = {
  furigana: /\[f k=(.+?) f=(.+?)\]/gi,
};

export function parseContent(content: string = '') {
  content = content.replace(regex.furigana, (_o, k, f) => {
    return `<span class="ruby">${k}<span class="rt">${f}</span></span>`;
  });

  return content;
}

export function Text(props: TextProps) {
  const { className, children, isCenter } = props;
  return <div className={classNames(style.root, { [style.center]: isCenter }, className)}>{children}</div>;
}

export function TextWithFurigana(props: TextWithFuriganaProps) {
  const { className, children } = props;
  return (
    <p className={classNames(style.root, className)} dangerouslySetInnerHTML={{ __html: parseContent(children) }} />
  );
}
