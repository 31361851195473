import React from 'react';

import { SectionHeader } from '../../components/section-header/section-header';
import { SectionList } from '../../components/section-list/section-list';
import { Text } from '../../components/text/text';
import Cross from '../../icons/landing/cross';
import GamePad from '../../icons/landing/game-pad';
import Time from '../../icons/landing/time';

import style from './section2.module.scss';

export function Section2() {
  return (
    <section className={style.root}>
      <div className={style.content}>
        <div className={style.left}>
          <SectionHeader heading="Plenty of gamified interactions!" category="Practice" />
          <Text className={style.text}>
            <p>Learning a new language can feel like an uphill battle.</p>
            <p>
              It’s easy to get discouraged when you don’t see any progress. The process takes time, practice, and a lot
              of dedication.
            </p>
            <p>
              However, at ImaWakatta, we make learning Japanese fun, interactive, and accessible for everyone using
              interesting gamified interactions that make it easier for you to pick up the skills.
            </p>
            <p>Our gamified interactions include:</p>
          </Text>
          <SectionList
            items={[
              'Conjugation mini-game',
              'Time-telling mini-game',
              '700+ multiple choice and word order quizzes',
              'Free hiragana and katakana practice',
            ]}
          />
          <Text className={style.text}>
            <p>
              These resources will allow you to learn a new language at your own pace, in your own time, and within the
              comfort of your own home.
            </p>
          </Text>
        </div>
        <div className={style.right}>
          <div className={style.imageWrapper}>
            <picture>
              <source type="image/webp" srcSet="assets/time-example.webp" />
              <img
                src="assets/time-example.png"
                alt="ImaWakatta is more than just reading"
                className={style.image}
                loading="lazy"
              />
            </picture>
          </div>
          <div className={style.donut} />
          <Cross className={style.cross} />
          <div className={style.yellowCircle}>
            <Time className={style.time} />
          </div>
          <div className={style.blueCircle}>
            <GamePad className={style.gamePad} />
          </div>
        </div>
      </div>
    </section>
  );
}
