import React from "react";
import Head from "next/head";

import { PricingSection } from "../features/pricing-section/pricing-section";
import { Section1 } from "../features/section1/section1";
import { Section2 } from "../features/section2/section2";
import { Section3 } from "../features/section3/section3";
import { Spaced } from "../features/spaced/spaced";
import { Hero } from "../features/hero/hero";
import { LandingLayout } from "../layouts/landing-layout/landing-layout";
import { StorySection } from "../features/story-section/story-section";

export default function Index() {
  return (
    <LandingLayout>
      <Head>
        <title>ImaWakatta - The best way to learn Japanese!</title>
        <meta
          name="description"
          content="Hundreds of lessons, interactions, quizzes, resources and audio. The best way to learn Japanese - ImaWakatta."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Hero />
      <main>
        <StorySection />
        <Section1 />
        <Section2 />
        <Section3 />
        <Spaced />
        <PricingSection />
      </main>
    </LandingLayout>
  );
}
