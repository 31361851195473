import React from 'react';

import { SectionHeader } from '../../components/section-header/section-header';
import { SectionList } from '../../components/section-list/section-list';
import { Text } from '../../components/text/text';

import style from './section3.module.scss';

export function Section3() {
  return (
    <section className={style.root}>
      <div className={style.wrapper}>
        <div className={style.left}>
          <div className={style.box} />
          <SectionHeader heading="Clean, easy to access resources" category="Resources" />
          <Text className={style.text}>
            <p>Bid farewell to eyesore UI and cluttered site designs.</p>
            <p>
              IamWakatta provides users with a smooth learning experience through a user-friendly app with all its
              resources available for review at your fingertips 24/7/365.
            </p>
            <p>
              We also have a huge vocabulary bank that can be used to accomplish your intellectual quests when you are
              stuck in unknown waters.
            </p>
            <p>Additionally, you need not worry about strict schedules or looming deadlines.</p>
            <p>
              Our program allows users to have a fun, stress-free, and flexible schedule, so you can not only master
              Japanese but also enjoy a serene state of mind.
            </p>
          </Text>
          <SectionList
            items={[
              'Review content anytime - no weird limits',
              'Spaced repetition learning',
              'Stacks of vocabulary, grouped, tagged and easy to search',
              'Hiragana and katakana presented in clean, easy to read reference tables',
            ]}
          />
        </div>
        <div className={style.right}>
          <div className={style.disc} />
          <div className={style.imageWrapper}>
            <picture>
              <source type="image/webp" srcSet="assets/fw_laptop_ima2.webp" width={841} height={513.267} />
              <img
                src="assets/fw_laptop_ima2.png"
                alt="Learn basic Japanese"
                className={style.image}
                loading="lazy"
                width={841}
                height={513.267}
              />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
}
