import React from 'react';

import { track } from '@/track';
import { SectionHeader } from '../../components/section-header/section-header';
import { Text } from '../../components/text/text';
import Cross from '../../icons/landing/cross';

import style from './story-section.module.scss';

export function StorySection() {
  return (
    <section className={style.root}>
      <div className={style.top}>
        <div className={style.card}>
          <div className={style.frameWrapper}>
            <iframe
              src="https://customer-p9ayi7g78vbqi04v.cloudflarestream.com/198ab3017e7af53fbc956255fcebbf6e/iframe?poster=https%3A%2F%2Fcustomer-p9ayi7g78vbqi04v.cloudflarestream.com%2F198ab3017e7af53fbc956255fcebbf6e%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
              loading="lazy"
              className={style.video}
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className={style.bottom}>
        <div className={style.left}>
          <SectionHeader heading="Start reading Japanese today" category="Stories" />
          <Text className={style.text}>
            <p>Learning Japanese is hard. So many kanji to remember, not to mention the grammar complexity!</p>
            <p>
              Have you been studying for months and still unable to read a simple article without looking up every
              second word? Don&apos;t worry, you&apos;re not alone.
            </p>
            <p>
              Stop your suffering and start reading today. ImaWakatta jump-starts your learning journey using advanced
              teaching techniques.
            </p>
            <p>
              ImaWakatta provides you a mix of traditional Japanese and western stories with professional voiceovers and
              line-by-line translations, all highlighted in realtime.
            </p>
            <p>
              Grammar and vocabulary overlays allow you to break down sentences into bite sized chunks and learn one
              step at a time.
            </p>
          </Text>
        </div>
        <div className={style.right}>
          <div className={style.donut} />
          <Cross className={style.cross} />
          <div className={style.image}>
            <picture>
              <source type="image/webp" srcSet="assets/0025.webp" />
              <img src="/assets/0025.png" alt="What is hiragana, katakana and kanji?" loading="lazy" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
}
