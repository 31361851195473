import React from 'react';
import { IconProps } from '../icon-props';

export default function CurlyArrow({ className = '' }: IconProps) {
  return (
    <svg className={className} viewBox="0 0 369 161" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M367.467 138.6L367.467 138.599C367.707 137.521 367.029 136.453 365.951 136.211L365.951 136.211L352.776 133.268C351.698 133.027 350.629 133.705 350.388 134.783L350.388 134.783C350.147 135.862 350.825 136.93 351.904 137.171L352.013 136.683L351.904 137.171C354.234 137.692 356.24 138.886 357.762 140.503C324.692 159.379 296.143 159.771 269.702 150.009C241.302 139.523 215.187 117.276 188.468 92.8585C185.098 89.7789 181.719 86.6651 178.326 83.5382C154.928 61.9764 130.851 39.7897 104.427 23.8487C74.4629 5.77339 41.3191 -4.37862 2.45318 3.3556C1.36734 3.57168 0.666438 4.63595 0.893575 5.71836C1.12128 6.80349 2.18925 7.48632 3.2645 7.27261C40.8822 -0.204226 73.0024 9.5637 102.361 27.2738C128.406 42.9859 152.153 64.8641 175.581 86.4491C178.982 89.5825 182.376 92.7097 185.77 95.811C212.429 120.173 239.08 142.966 268.317 153.761C295.975 163.973 325.817 163.411 359.962 143.852C360.887 145.999 361.168 148.447 360.619 150.903C360.379 151.98 361.057 153.05 362.135 153.29C363.214 153.531 364.282 152.852 364.523 151.774L364.035 151.665L364.523 151.774L367.467 138.6Z" />
    </svg>
  );
}
