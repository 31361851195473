import React from 'react';
import { IconProps } from '../icon-props';

export default function Cross({ className = '' }: IconProps) {
  return (
    <svg className={className} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M32.1611 14.1638H21.8374V3.83616C21.8374 1.71674 20.1177 0 17.9985 0C15.8823 0 14.1657 1.71674 14.1657 3.83616V14.1638H3.83887C1.71659 14.1638 0 15.8806 0 18C0 20.1194 1.71659 21.8362 3.83887 21.8362H14.1657V32.1638C14.1657 34.2833 15.8823 36 17.9985 36C20.1177 36 21.8374 34.2833 21.8374 32.1638V21.8362H32.1611C34.2804 21.8362 36 20.1194 36 18C36 15.8806 34.2804 14.1638 32.1611 14.1638Z"
      />
    </svg>
  );
}
