import React from 'react';
import Check from '../../icons/fa/solid/check';

import style from './section-list.module.scss';

type Props = {
  items: Array<string>;
};

export function SectionList(props: Props) {
  const { items } = props;
  return (
    <ul className={style.root}>
      {items.map((item, key) => (
        <li key={key} className={style.item}>
          <span className={style.tick}>
            <Check />
          </span>
          {item}
        </li>
      ))}
    </ul>
  );
}
