import React from 'react';
import { SectionHeader } from '../../components/section-header/section-header';
import CurlyArrow from '../../icons/landing/curly-arrow';
import { Text } from '../../components/text/text';
import style from './spaced.module.scss';

export function Spaced() {
  return (
    <section className={style.root}>
      <div className={style.wrapper}>
        <SectionHeader heading="What is spaced repetition learning?" />
        <div className={style.columns}>
          <div className={style.left}>
            <Text className={style.text}>
              <p>
                &quot;Spaced repetition learning&quot; supercharges your learning efficiency by showing you the
                questions you find most difficult, more frequently. And the questions you find most easy will be shown
                less often.
              </p>
              <p>
                Our smart platform will monitor your progress on every question. If you get a question correct,
                ImaWakatta will increase &quot;space&quot; (the time) before it is repeated again. If you get the
                question correct again there will be an even larger &quot;space&quot; before the next repetition.
              </p>
            </Text>
          </div>
          <div className={style.right}>
            <div className={style.imageWrapper}>
              <picture>
                <source type="image/webp" srcSet="assets/illustrations/robot.webp" />
                <img
                  src="assets/illustrations/robot.png"
                  alt="Our ImaWakatta platform provides intelligent and efficient learning paths to maximize your time"
                  className={style.image}
                  loading="lazy"
                  width={942}
                  height={930}
                />
              </picture>
            </div>
          </div>
        </div>
        <CurlyArrow className={style.curlyArrow} />
      </div>
    </section>
  );
}
