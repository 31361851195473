import React from 'react';

import { SectionHeader } from '../../components/section-header/section-header';
import { SectionList } from '../../components/section-list/section-list';
import { Text } from '../../components/text/text';
import Cross from '../../icons/landing/cross';
import Squiggle from '../../icons/landing/squiggle';

import style from './section1.module.scss';

export function Section1() {
  return (
    <section className={style.root}>
      <div className={style.content}>
        <div className={style.left}>
          <SectionHeader heading="Detailed, in-depth lessons." category="Lessons" />
          <Text className={style.text}>
            <p>It’s hard to find a good teacher, and it’s hard to find a good textbook.</p>
            <p>At ImaWakatta, we have the best of both worlds.</p>
            <p>
              With nearly 100 top-notch lessons, along with thousands of audio clips from native Japanese speakers, you
              will never have to worry about running out of quality content. These lessons, by our expert teachers, will
              allow you to comprehend every single Japanese word you come across.
            </p>
            <p>And before your ink is dry, you’ll be buzzing like a bee in Japanese.</p>
          </Text>
          <SectionList
            items={[
              'Written by native Japanese teachers',
              'Loads of example sentences',
              'Exceptions and gotchas highlighted',
              'Frequent updates',
            ]}
          />
        </div>
        <div className={style.right}>
          <div className={style.disc} />
          <div className={style.imageWrapper}>
            <picture>
              <source type="image/webp" srcSet="/assets/fw_laptop_ima1.webp" width={841} height={513.267} />
              <img
                src="/assets/fw_laptop_ima1.png"
                alt="Learn basic Japanese"
                className={style.image}
                loading="lazy"
                width={841}
                height={513.267}
              />
            </picture>
          </div>
          <Squiggle className={style.squiggle} />
          <Cross className={style.cross} />
          <div className={style.donut} />
        </div>
      </div>
    </section>
  );
}
