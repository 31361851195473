import classNames from 'classnames';
import React, { ReactNode } from 'react';
import style from './section-header.module.scss';

type Props = {
  heading: string;
  category?: string;
};

export function SectionHeader(props: Props) {
  const { heading, category } = props;
  return (
    <header className={classNames(style.root)}>
      {category && <h2 className={style.category}>{category}</h2>}
      <h3 className={style.heading}>{heading}</h3>
    </header>
  );
}
