import React from 'react';
import { PushButton } from '../../components/push-button/push-button';
import Check from '../../icons/fa/solid/check';
import CurlyArrow from '../../icons/landing/curly-arrow';
import MarkerUnderlineNarrow from '../../icons/landing/marker-underline-narrow';
import Squiggle from '../../icons/landing/squiggle';

import style from './pricing-section.module.scss';
import { track } from '@/track';

export function PricingSection() {
  return (
    <section className={style.root} id="pricing">
      <div className={style.content}>
        <h2 className={style.heading}>Easy peasy pricing</h2>
        <p className={style.byline}>Two plans - free and paid. Cancel anytime.</p>
      </div>
      <div className={style.card}>
        <div className={style.column}>
          <h2 className={style.price}>FREE</h2>
          <div className={style.priceByline}>forever</div>
          <MarkerUnderlineNarrow className={style.squiggle} />
          <ul className={style.list}>
            <li>
              <Check />
              <span>4x free stories</span>
            </li>
            <li>
              <Check />
              <span>40 x free grammar lessons</span>
            </li>
            <li>
              <Check />
              <span>Over 200 grammar quiz</span>
            </li>
            <li>
              <Check />
              <span>Hiragana/katakana practice</span>
            </li>
            <li>
              <Check />
              <span>Kana reference tables</span>
            </li>
            <li>
              <Check />
              <span>Discussion boards</span>
            </li>
          </ul>
        </div>
        <div className={style.column}>
          <h2 className={style.price}>$5</h2>
          <div className={style.priceByline}>per month (USD)</div>
          <MarkerUnderlineNarrow className={style.squiggle} />
          <ul className={style.list}>
            <li>
              <span>Everything in FREE plus:</span>
            </li>
            <li>
              <Check />
              <span>Over 10 additional stories and more coming</span>
            </li>
            <li>
              <Check />
              <span>150+ grammar lessons and growing</span>
            </li>
            <li>
              <Check />
              <span>Time practice</span>
            </li>
            <li>
              <Check />
              <span>Verb conjugation practice</span>
            </li>
            <li>
              <Check />
              <span>Vocal lists</span>
            </li>
            <li>
              <Check />
              <span>Discord premium channel (ask our Japanese teachers questions anytime!)</span>
            </li>
            <li>
              <Check />
              <span>Adding new stories and grammar lessons every month</span>
            </li>
          </ul>
        </div>
        <PushButton
          className={style.button}
          href={`${process.env.NEXT_PUBLIC_APP_URL}/register`}
          onClick={() => track('register', { from: 'pricing-section' })}
        >
          Register for free
        </PushButton>
      </div>
      <CurlyArrow className={style.curlyArrow} />
      <Squiggle className={style.blueSquiggle} />
    </section>
  );
}
