/* eslint-disable @next/next/no-img-element */
import React from 'react';

import { PushButton } from '../../components/push-button/push-button';
import MarkerUnderlineWide from '../../icons/landing/marker-underline-wide';

import style from './hero.module.scss';

export function Hero() {
  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className={style.imageContainer}>
          <picture>
            <source type="image/webp" srcSet="/assets/onigiri-chan.webp" width={496} height={440} />
            <img src="/assets/onigiri-chan.png" alt="The best way to learn Japanese" width={496} height={440} />
          </picture>
        </div>
        <div className={style.textContainer}>
          <h1 className={style.heading}>
            Forget subtitles, start{' '}
            <span className={style.underlineWrapper}>
              <span>understanding</span>
              <MarkerUnderlineWide className={style.underline} />
            </span>{' '}
            Japanese!
          </h1>
          <p className={style.byline}>
            We know it’s tiring to follow the subtitles when binging your favorite shows. Stop getting lost in
            translation and learn Japanese today!
          </p>
          <PushButton className={style.button} href={`${process.env.NEXT_PUBLIC_APP_URL}/register`}>
            Let&apos;s do this!
          </PushButton>

          <a href="https://apps.apple.com/au/app/imawakatta/id6446445492" target="_blank" rel="noreferrer">
            <img
              src="/assets/apple-download.svg"
              alt="Download on the App Store"
              className={style.download}
              width={180}
              height={60}
            />
          </a>
        </div>
      </div>
    </div>
  );
}
